<template>
  <div>
    <div class="border rounded q-ma-sm" v-if="!isPlacesLoaded || !isLoadingFinished">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ $t('Places are not loaded yet') }}
        </div>
      </div>
    </div>

    <div class="border rounded q-ma-sm" v-if="!isPlacesLoaded && isLoadingFinished">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ $t('There is no places in that delivery request, impossible to get label') }}
        </div>
      </div>
    </div>

    <div v-if="isPlacesLoaded && isLoadingFinished">
      <div class="row">
        <div class="col">
          <q-card-section class="q-pt-lg q-mt-sm" v-if="isLoading">
            <q-linear-progress stripe size="10px" class="" ref="progress" :value="progressValue"/>
          </q-card-section>
        </div>

        <div class="col-4 q-pa-md text-right">
          <q-btn
              color="blue-6"
              icon="aspect_ratio"
              size="md"
              no-caps
              @click="handleBoxSizes()"
              :disable="isLoading"
              :label="$t('Set box sizes')"
              class="q-mr-sm"
          />

          <q-btn
              color="green-6"
              icon="add_box"
              size="md"
              no-caps
              @click="createPlace()"
              :disable="isLoading"
              :label="$t('Add box')"
          />
        </div>
      </div>

      <q-card-section v-if="boxSizesOpen" class="text-center">
        <div class="row no-wrap">
          <div class="col align-items-center text-left">
            <q-btn-group class="q-px-xs">
              <editable-input-field
                  :value="boxSetSizes.from"
                  :input-label="`Start box`"
                  @change-catch-all="handleCloseEditItem"
                  :update-function="newValue => updateSetBox('from', newValue)"
              ></editable-input-field>
              <editable-input-field
                  :value="boxSetSizes.to"
                  :input-label="`Finish box`"
                  @change-catch-all="handleCloseEditItem"
                  :update-function="newValue => updateSetBox('to', newValue)"
              ></editable-input-field>
            </q-btn-group>
          </div>

          <div class="col-2">
            <q-btn-group class="q-px-xs">
              <editable-input-field
                  :label="$t('Weight')"
                  :value="boxSetSizes.weight"
                  :input-label="`${this.$t('Weight')} (${$t(appOptions.defaultDimensions.weightUnit)})`"
                  :update-function="newValue => updateSetBox('weight', newValue)"
                  @change-catch-all="handleCloseEditItem"
              ></editable-input-field>
            </q-btn-group>
          </div>

          <div class="col-4">
            <q-btn-group class="q-px-xs">
              <editable-input-field
                  :label="$t('W')"
                  :value="boxSetSizes.dimensions.x"
                  :input-label="`${$t('W')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                  :update-function="newValue => updateSetBoxDimensions('x', newValue)"
                  @change-catch-all="handleCloseEditItem"
              ></editable-input-field>

              <editable-input-field
                  :label="$t('H')"
                  :value="boxSetSizes.dimensions.y"
                  :input-label="`${$t('H')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                  :update-function="newValue => updateSetBoxDimensions('y', newValue)"
                  @change-catch-all="handleCloseEditItem"
              ></editable-input-field>

              <editable-input-field
                  :label="$t('L')"
                  :value="boxSetSizes.dimensions.z"
                  :input-label="`${$t('L')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                  :update-function="newValue => updateSetBoxDimensions('z', newValue)"
                  @change-catch-all="handleCloseEditItem"
              ></editable-input-field>
            </q-btn-group>
          </div>

          <div class="col-2 text-right">
            <q-btn
                color="blue-6"
                icon="aspect_ratio"
                size="md"
                no-caps
                @click="updateBoxSizes()"
                :disable="isLoading"
                :label="$t('Set')"
                class="q-mr-sm"
            />
          </div>
        </div>
      </q-card-section>

      <div class="border rounded q-ma-sm"
           v-if="isPlacesLoaded && isLoadingFinished && getPoolPlace() && places.length === 0">
        <div class="text-dark q-px-sm row items-stretch">
          <div
              class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
              style="line-height: 1.2;"
          >
            {{ $t('There are no places, please add one') }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6" v-if="getPoolPlace()">
          <product-pool
              :places="currentPlaces"
              :place="getPoolPlace()"
              :scan-input="scanInput"
              :disabled="disabled"
              :item-to-move="itemToMove"
              :is-blocked="isLoading"
              @change-catch-all="handleCatchAll"
              @move-items="moveItems"
              @mark-shipped="handleMarkNotShippedModal"
              @request-move-items="handleMoveItemModal"
              @request-item-instructions="handleItemInstructions"
          />
        </div>

        <div :class="getPoolPlace() ? 'col-6' : 'col-12'">
          <boxes-pool
              :scan-input="scanInput"
              :pool-enabled="getPoolPlace()"
              :places="currentPlaces"
              :place="getPoolPlace()"
              :disabled="disabled"
              :item-to-move="itemToMove"
              :order-id="deliveryRequest._embedded.order?.id"
              @move-items="moveItems"
              @mark-shipped="handleMarkNotShippedModal"
              @request-move-items="handleMoveItemModal"
              @request-item-instructions="handleItemInstructions"
              @change-catch-all="handleCatchAll"
              @delete="handleRemove"
              @moved="handleMove"
              @clone-place="clonePlace"
              @update-place="updatePlace"
          />

          <q-item
              class="justify-center text-h6"
          >
            {{ $t('Total boxes') + ': ' + placesStats.count }}
          </q-item>

          <div class="q-pa-lg flex flex-center">
            <q-pagination
                v-model="boxesStats.page"
                :max="placesStats.total"
            />
          </div>
        </div>
      </div>

      <q-item
          v-if="currentPlaces.length > 0"
          class="justify-center text-h6"
      >
        <span>
          {{ $t('Total Items') + ': ' + total.count }}, {{
            $t('Total Return') + ': ' + total.returned
          }},{{ $t('Total Shipped') + ': ' + total.shipped }}, {{ $t('SKU') + ': ' + total.skus }}
        </span>
      </q-item>
    </div>

    <labelling-mark-not-shipped-modal ref="labellingNotShipped" @submit="handleMarkAsNotShipped"/>

    <labelling-movement-modal ref="labellingMovement" @move-items="moveItems"/>

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Components
import { mapGetters, mapMutations } from 'vuex'
import ProductPool from '@/apps/app/components/delivery-requests/ProductPool.vue'
import BoxesPool from '@/apps/app/components/delivery-requests/BoxesPool.vue'
import LabellingMovementModal from '@/apps/app/components/modals/LabellingMovementModal.vue'
import _ from 'lodash'
import LabellingMarkNotShippedModal from '@/apps/app/components/modals/LabellingMarkNotShippedModal.vue'
import { InstructionsService } from '@/apps/app/services/instructions.service'
import ConfirmModal from '@/apps/app/components/confirm-modal/ConfirmModal.vue'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'

export default {
  name: 'DRBoxing',
  emits: ['change', 'change-catch-all', 'moved', 'handle-refresh-boxes', 'places-change', 'places-pool-change'],
  components: {
    EditableInputField,
    ConfirmModal,
    LabellingMarkNotShippedModal,
    LabellingMovementModal,
    ProductPool,
    BoxesPool,
  },
  props: {
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
    title: {
      type: String,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    placeItemsPool: {
      type: Object,
      required: false,
      default () {
        return null
      }
    },
    places: {
      type: Array,
      required: true,
      default () {
        return null
      }
    },
    placesStats: {
      type: Object,
      required: true,
      default () {
        return {
          count: 1,
          page: 1,
          total: 1,
        }
      }
    },
    isLoadingFinished: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  data () {
    return {
      instructionsService: null,
      poolPlace: null,
      currentPlaces: [],
      isLoading: false,
      hasChange: false,
      progressValue: 0,
      itemToMove: null,
      boxesStats: {
        count: 1,
        page: 1,
        total: 1,
      },
      boxSizesOpen: false,
      boxSetSizes: {
        from: 1,
        to: null,
        weight: null,
        dimensions: {
          x: null,
          y: null,
          z: null,
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'deliveryRequest',
    ]),
    total () {
      let orderProducts = []

      return this.places.reduce((acc, place) => {
        acc.count += place.items.reduce((acc, item) => {
          if (!orderProducts.includes(item.orderProduct?.id)) {
            orderProducts.push(item.orderProduct?.id)
          }

          return acc + Number(item.count || 0)
        }, 0)

        acc.returned += place.items.reduce((acc, item) => {
          if (item?.orderProduct?.state === 'return') {
            return acc + 1
          }
          return acc
        }, 0)

        acc.shipped += place.items.reduce((acc, item) => {
          if (item?.orderProduct?.state === 'shipped') {
            return acc + 1
          }
          return acc
        }, 0)

        acc.skus = orderProducts.length

        return acc
      }, { count: 0, skus: 0, returned: 0, shipped: 0 })
    },
    isPlacesLoaded () {
      return this.currentPlaces && this.currentPlaces.length > 0
    }
  },
  watch: {
    scanInput (barcode) {
      if (!barcode.type && this.poolPlace && this.poolPlace.items) {
        let place = this.poolPlace

        const string = `${barcode.value}`
        const found = place.items.filter(e => Object.values(e.barcodes).includes(string))
        if (found.length > 0) {
          const itemToMove = found[0]
          this.itemToMove = itemToMove
          this.$refs.labellingMovement.open([itemToMove], place, this.places, this.getPoolPlace())

          return
        }
      }
    },
    places: {
      handler (value) {
        this.currentPlaces = Object.values(value)

        this.sortCurrentPlaces()
      },
      deep: true
    },
    placeItemsPool: {
      handler (value) {
        this.poolPlace = value
      },
      deep: true
    },
    placesStats: {
      handler (value) {
        this.boxesStats = value
      },
      deep: true
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params), this.$router, this.$route)

    this.currentPlaces = Object.values(this.places)

    if (!this.deliveryRequest && this.externalDeliveryRequest) {
      this.setDeliveryRequest(this.externalDeliveryRequest)
    }
  },
  unmounted () {
    this.currentPlaces = []
  },
  methods: {
    ...mapMutations([
      'addWarningNotification',
      'addErrorNotification',
      'setPlaces'
    ]),
    sortCurrentPlaces () {
      if (this.currentPlaces.length > 0) {
        this.currentPlaces.sort((a, b) => {
          return a.priority - b.priority
        })
      }
    },
    getPoolPlace () {
      return this.poolPlace
    },
    getPlaceTotalSum (place) {
      return place.items.reduce((sum, item) => {
        return sum + ((Number(item.count) || 0) * (Number(item.payment) || 0))
      }, 0)
    },
    updatePlacesList (updatedPlace) {
      let places = this.currentPlaces

      const index = places.findIndex(place => {
        return place.id === updatedPlace.id
      })

      if (index !== -1 && updatedPlace.state === 'deleted') {
        places.splice(index, 1)
      } else if (index !== -1 && updatedPlace.state !== 'deleted') {
        // If the item exists in the array, replace it with the updated version
        places[index] = updatedPlace
      } else {
        places.unshift(updatedPlace)
      }

      if (updatedPlace.type === 'simple') {
        this.setPlaces(places)
      }
    },
    updatePlacesListByItem (updatedItem) {
      let updatedPlace = updatedItem._embedded.place

      this.updatePlacesList(updatedPlace)
    },
    updateItemPlace (place, itemToUpdate) {
      place.items = Object.values(place?.items)

      let index = place.items.findIndex((item) => {
        return item.id === itemToUpdate.id
      })

      if (index !== -1) {
        place.items[index] = itemToUpdate
      }
    },
    removeItemPlace (place, itemToRemove) {
      place.items = Object.values(place.items)

      const index = place.items.findIndex((item) => {
        return item.id === itemToRemove.id
      })

      if (index !== -1) {
        place.items.splice(index, 1)
      }
    },
    convertPlaceToPool (place) {
      this.isLoading = true
      return this.$service.deliveryServicePlace.save({
        priority: 0,
        type: 'pool',
        eav: { ['delivery-services-request-place-is-pool']: true }
      }, place.id).then(updatedPlace => {
        this.$emit('places-pool-change', updatedPlace)

        this.isLoading = false
      })
    },
    handleRemove (currentPlace, removed, flag) {
      if (flag) {
        this.isLoading = true
        return this.$service.deliveryServicePlace.save({
          eav: { ['delivery-services-request-place-is-pool']: false },
          type: 'simple',
          priority: 1
        }, currentPlace.id).then(updatedPlace => {
          this.currentPlaces = [
            updatedPlace
          ]

          this.$emit('places-pool-change', null)

          this.isLoading = false
        })
      } else {
        this.updatePlacesList(removed)

        return
      }
    },
    async updatePlace (e) {
      this.isLoading = true
      if (e.data?.state === 'filling') {
        let currentFillingPlaces = this.currentPlaces.filter(item => item?.state === 'filling')
        if (currentFillingPlaces.length > 0) {
          if (currentFillingPlaces.length > 1) {
            this.addWarningNotification('There are more then 1 filling place')

            return
          }

          await this.$service.deliveryServicePlace.save({
            state: 'active'
          }, currentFillingPlaces[0].id).then(updatedPlace => {
            this.updatePlacesList(updatedPlace)
          })
        }
      }

      this.$service.deliveryServicePlace.save(e.data, e.sourcePlace?.id).then(updatedPlace => {
        this.updatePlacesList(updatedPlace)

        this.isLoading = false
      })
    },
    handleBoxSizes () {
      this.boxSizesOpen = !this.boxSizesOpen
    },
    updateSetBox (state, value) {
      this.boxSetSizes[state] = value
    },
    updateSetBoxDimensions (state, value) {
      this.boxSetSizes.dimensions[state] = value
    },
    async updatePlaceAsync (update, place) {
      place = { ...place, ...update }

      await this.$service.deliveryServicePlace.save(update, place.id).then((updatedPlace) => {
        this.updatePlacesList(updatedPlace)

        return Promise.resolve(updatedPlace)
      })
    },
    async updateBoxSizes () {
      let update = _.cloneDeep(this.boxSetSizes)
      delete update.from
      delete update.to

      this.isLoading = true
      this.currentPlaces.forEach((place, i) => {
        if (place.priority >= this.boxSetSizes.from && place.priority <= this.boxSetSizes.to) {
          this.updatePlaceAsync(update, place)

          this.setProgress((i / (this.boxSetSizes.to - this.boxSetSizes.from)).toFixed(2))
        }
      })

      this.isLoading = false
    },
    createPlace (data) {
      if (data === undefined) {
        data = {
          deliveryRequest: this.deliveryRequest.id,
          dimensions: null,
          volume: null,
          weight: null
        }
      }

      let itemsPool = null
      if (!this.getPoolPlace() && this.currentPlaces && this.currentPlaces.length === 1) {
        data.priority = 1

        itemsPool = this.currentPlaces[0]
      } else {
        data.priority = this.currentPlaces[0].priority + 1
      }

      this.isLoading = true
      return this.$service.deliveryServicePlace.save(data).then((updatedPlace) => {
        if (!this.getPoolPlace()) {
          this.convertPlaceToPool(itemsPool)

          this.currentPlaces = []
        }

        this.updatePlacesList(updatedPlace)

        return Promise.resolve(updatedPlace)
      }).finally((updatedPlace) => {
        this.isLoading = false

        return Promise.resolve(updatedPlace)
      })
    },
    setProgress (progressValue) {
      this.progressValue = progressValue
    },
    async clonePlace (event) {
      let placeData = event.place
      let items = _.cloneDeep(event.items)
      let quantity = event.quantity

      const promises = []

      let currentFillingPlaces = this.currentPlaces.filter(item => item?.state === 'filling')
      if (currentFillingPlaces.length > 0) {
        if (currentFillingPlaces.length > 1) {
          this.addWarningNotification('There are more then 1 filling place')

          return
        }

        let fillingPlace = currentFillingPlaces[0]
        let stateValue = 'active'
        if (Array.isArray(fillingPlace.items) && fillingPlace.items.length === 0) {
          stateValue = 'deleted'
        }

        await this.$service.deliveryServicePlace.save({
          state: stateValue
        }, fillingPlace.id).then(updatedPlace => {
          this.updatePlacesList(updatedPlace)
        })
      }

      let shouldBreak = false
      for (let i = 0; i < quantity && !shouldBreak; i++) {
        await this.createPlace(placeData).then((updatedPlace) => {
          this.setProgress((i / quantity).toFixed(2))

          let poolPlace = this.getPoolPlace()

          let itemsToMove = []
          items.forEach((item) => {
            let orderProductId = item.orderProduct?.id
            if (!orderProductId) {
              orderProductId = item._embedded.orderProduct?.id
            }

            let placeItem = poolPlace.items.find(item => {
              if (!item) {
                return false
              }

              let itemOrderProductId = item.orderProduct?.id
              if (!itemOrderProductId) {
                itemOrderProductId = item._embedded.orderProduct?.id
              }

              return itemOrderProductId === orderProductId
            })

            if (placeItem.count < item.count) {
              this.addWarningNotification('Quantity not enough')

              shouldBreak = true

              return Promise.reject('Quantity not enough')
            }

            placeItem.quantityToMove = item.count

            itemsToMove.push(placeItem)
          })

          const moveItemsPromise = this.moveItems({
            sourcePlace: this.getPoolPlace(),
            targetPlace: updatedPlace,
            items: itemsToMove
          })

          promises.push(moveItemsPromise)
        })

        await Promise.all(promises)
      }
    },
    async handleMarkAsNotShipped (items = []) {
      this.isLoading = true
      let index = 0
      const total = items.length

      for (const item of items) {
        try {
          this.setProgress((index / total).toFixed(2))
          index++
          if (item.eav && item.state !== 'return') {
            let result = {
              state: 'return',
              eav: item.eav
            }
            await this.$service.deliveryServicePlaceItems.save(result, item.id)
          } else if (item.eav && item.state === 'return') {
            let result = {
              eav: item.eav
            }
            await this.$service.deliveryServicePlaceItems.save(result, item.id)
          } else {
            await this.$service.deliveryServicePlaceItems.save({ state: 'return' }, item.id)
          }
        } catch (error) {
          // Handle any errors that might occur during the requests
          console.error(error)
        } finally {
          this.$emit('handle-refresh-boxes')
        }
      }
      this.enableCatchAll()
      this.isLoading = false
    },
    async moveItems (event) {
      let sourcePlace = event.sourcePlace
      let targetPlace = event.targetPlace

      // Create a function to move a single item
      const moveSingleItem = async (e) => {
        try {
          let placeItems = targetPlace.items

          let orderProductId = e.orderProduct?.id
          if (!orderProductId) {
            orderProductId = e._embedded?.orderProduct?.id
          }

          if (!orderProductId) {
            this.addWarningNotification('An order product not set')
          }

          let placeItem = placeItems.find(item => {
            if (!item) {
              return false
            }

            let itemOrderProductId = item.orderProduct?.id
            if (!itemOrderProductId) {
              itemOrderProductId = item._embedded.orderProduct?.id
            }

            return itemOrderProductId === orderProductId
          })

          if (e.quantityToMove < e.count || placeItem) {
            let placeData = {}

            if (!placeItem) {
              Object.entries(e).forEach(([key, value]) => {
                if (value instanceof Object && value.id) {
                  placeData[key] = value.id
                } else {
                  placeData[key] = value
                }
              })

              if (!e.quantityToMove) {
                this.addWarningNotification('Quantity to move is empty')
              }

              placeData['count'] = e.quantityToMove
              placeData['place'] = targetPlace.id

              delete placeData.id
              delete placeData.quantityToMove
              delete placeData.updated
              delete placeData.created
              delete placeData._links
            } else {
              if (!e.quantityToMove) {
                this.addWarningNotification('Quantity to move is empty')
              }

              placeData = {
                place: targetPlace.id,
                count: parseFloat(placeItem.count) + parseFloat(e.quantityToMove)
              }
            }

            if (placeData._embedded) {
              placeData.deliveryRequest = placeData._embedded?.deliveryRequest?.id
              placeData.orderProduct = placeData._embedded?.orderProduct?.id
              delete placeData._embedded
            }

            this.isLoading = true
            const item = await this.$service.deliveryServicePlaceItems.save(placeData, placeItem?.id)
            this.isLoading = false

            this.updatePlacesListByItem(item)

            this.isLoading = true
            if (parseFloat(e.count) > parseFloat(e.quantityToMove)) {
              let result = parseFloat(e.count) - parseFloat(e.quantityToMove)
              const item2 = await this.$service.deliveryServicePlaceItems.save({ count: result }, e.id)
              console.log(sourcePlace)
              this.updateItemPlace(sourcePlace, item2)
              this.isLoading = false
              return item2
            } else {
              const item2 = await this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, e.id)
              this.removeItemPlace(sourcePlace, item2)
              this.isLoading = false
              return item2
            }
          } else {
            this.isLoading = true
            const item = await this.$service.deliveryServicePlaceItems.save({ 'place': targetPlace.id }, e.id)
            this.isLoading = false

            this.updatePlacesListByItem(item)
            this.removeItemPlace(sourcePlace, item)
            return item
          }
        } catch (error) {
          // Handle errors here
          console.error('Error moving item:', error)
          throw error
        }
      }

      // Use Promise.all to execute moveSingleItem for each item
      const promises = event.items.map(moveSingleItem)

      try {
        const results = await Promise.all(promises)
        return results
      } catch (error) {
        console.error('Error moving items:', error)
        throw error
      }
    },
    handleMove (dimensions) {
      this.$emit('moved', dimensions)
    },
    handleMarkNotShippedModal (event) {
      this.disableCatchAll()
      this.$refs.labellingNotShipped.open(event.items)
    },
    handleMoveItemModal (event) {
      this.$refs.labellingMovement.open(event.items, event.sourcePlace, this.places, this.getPoolPlace(), event.targetPlace)
    },
    handleItemInstructions (event) {
      event.items.forEach((item) => {
        if (item.orderProduct?.eav && item.orderProduct?.eav['storage-assembling-instructions']) {
          console.log(item.orderProduct?.eav['storage-assembling-instructions'])
          this.instructionsService.execute(item.orderProduct?.eav['storage-assembling-instructions'])
        } else {
          this.addWarningNotification('No instructions found')
        }
      })
    },
    handleCloseEditItem (value) {
      this.$eventBus.update('catchAll', {
        'catchAll': value
      })
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    },
    handleCatchAll (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    }
  }
}
</script>

